<template>
  <div>
    <!-- 还需要一个系统配置的功能 配置全局的 logo 名称  图片-->
    <!--  系统集合配置： 默认首页 页面标题 标题点击跳转 页面logo 页面logo跳转 隐藏菜单 -->
    <el-dialog title="书籍" :visible.sync="drawer" :before-close="handleClose" :max-height="maxHeight" width="60%">
      <div style="margin: 0px 0px 10px 0px ;">
        <span slot="footer">
          <el-button @click="clear">取 消</el-button>
          <el-button type="primary" @click="saveOrUpdateDataButton">确 定</el-button>
          <el-button type="primary" @click="checkExistButton()">检测</el-button>
        </span>
      </div>
      <div>
        <span style="color: red;">
          <div v-html="this.checkBookDat.errorDataInfoStatus"></div>
        </span>

        <span style="color: orange;">
          <div v-html="this.checkBookDat.errorDatawarningData"></div>
        </span>
      </div>


      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="书籍编码" prop="code">
              <el-input v-model="ruleForm.code" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="信息状态" prop="infoStatus">
              <el-input v-model="ruleForm.infoStatus" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="书名" prop="name">
              <el-input v-model="ruleForm.name" :disabled="this.isEdit" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="作者" prop="author">
              <el-input v-model="ruleForm.author" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="类别" prop="category">
              <el-radio-group v-model="ruleForm.category">
                <el-radio label="1">实体</el-radio>
                <el-radio label="2">电子</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>


        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="分类" prop="categoryLevelFirst">
              <el-input v-model="ruleForm.categoryLevelFirst" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="阅读状态" prop="readingStatus">
              <el-select v-model="ruleForm.readingStatus" placeholder="请选择">
                <el-option v-for="item in readingStatusList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="this.ruleForm.category == 2">
            <el-form-item label="书籍链接" prop="bookLink">
              <el-input v-model="ruleForm.bookLink"></el-input>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="评分" prop="rating">
              <el-input v-model="ruleForm.rating"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="标签" prop="tags">
              <el-input v-model="ruleForm.tags" />
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="this.ruleForm.readingStatus == 2">
            <el-form-item label="阅读开始日期" prop="startReadingDate">
              <el-date-picker v-model="ruleForm.startReadingDate" type="date" value-format="yyyy-MM-dd"
                placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="this.ruleForm.readingStatus == 3 || this.ruleForm.readingStatus == 4">
            <el-form-item label="阅读/停止结束日期" prop="stopReadingDate">
              <el-date-picker v-model="ruleForm.stopReadingDate" type="date" value-format="yyyy-MM-dd"
                placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="this.ruleForm.readingStatus == 3">
            <el-form-item label="停止原因" prop="stopReason">
              <el-input v-model="ruleForm.stopReason"></el-input>
            </el-form-item>
          </el-col>

        </el-row>


        <el-row>
          <el-col :span="8" v-if="this.ruleForm.readingStatus != 1">
            <el-form-item label="阅读进度" prop="readingProgress">
              <el-slider v-model="ruleForm.readingProgress"></el-slider>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="重要章节" prop="importantChapters">
              <el-input v-model="ruleForm.importantChapters"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="存放位置" prop="storageLocation">
              <el-input v-model="ruleForm.storageLocation"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="购买时间" prop="purchaseTime">
                <el-date-picker v-model="ruleForm.purchaseTime" type="date" value-format="yyyy-MM-dd"
                placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="购买价格" prop="purchasePrice">
              <el-input v-model="ruleForm.purchasePrice"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="购买链接" prop="purchaseLink">
              <el-input v-model="ruleForm.purchaseLink"></el-input>
            </el-form-item>
          </el-col>
        </el-row>


        <el-row>
          <el-col :span="22">
            <el-form-item label="书籍大概" prop="bookSynopsis">
              <el-input v-model="ruleForm.bookSynopsis"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="是否收藏" prop="isFavorite">
              <el-checkbox :checked="ruleForm.isFavorite === 1 ? true : false" :true-label="1" :false-label="0"
                v-model="ruleForm.isFavorite"></el-checkbox>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="是否公开" prop="isPublic">
              <el-checkbox v-model="ruleForm.isPublic"></el-checkbox>
            </el-form-item>
          </el-col> -->
          <el-col :span="6">
            <el-form-item label="是否提醒" prop="isReminder">
              <el-checkbox v-model="ruleForm.isReminder" :checked="ruleForm.isReminder === 1 ? true : false"
                :true-label="1" :false-label="0"></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="是否标记" prop="isMarked">
              <el-checkbox v-model="ruleForm.isMarked" :checked="ruleForm.isMarked === 1 ? true : false" :true-label="1"
                :false-label="0"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="图片" prop="name">
            <el-upload class="upload-demo" :action="uploadUrl" :on-success="handleSuccess" :list-type="listType"
              :on-exceed="handleExceed" :auto-upload="false" :file-list="fileList" :limit="3">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
              <div slot="tip" class="el-upload__tip">
                只能上传3个文件，每个文件不超过500kb，且只能上传jpg/png文件
              </div>
            </el-upload>
          </el-form-item>
        </el-row>
        <el-row>
          <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
            <el-tab-pane label="高级属性" name="first">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="语言" prop="language" label-position="left">
                    <el-input v-model="ruleForm.language" style="width: 80%" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="ISBN" prop="isbn" label-position="left">
                    <el-input v-model="ruleForm.isbn" style="width: 80%" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="版本" prop="edition" label-position="left">
                    <el-input v-model="ruleForm.edition" style="width: 80%" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="书籍格式" prop="bookFormat" label-position="left">
                    <el-input v-model="ruleForm.bookFormat" placeholder="精装、平装" style="width: 80%" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="推荐来源" prop="recommendationSource" label-position="left">
                    <el-input v-model="ruleForm.recommendationSource" style="width: 80%" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="推荐文案" prop="recommendationCopy" label-position="left">
                    <el-input v-model="ruleForm.recommendationCopy" style="width: 80%" />
                  </el-form-item>
                </el-col>
              </el-row>


              <el-row>
                <el-col :span="8">
                  <el-form-item label="参考文献" prop="referencesTxt" label-position="left">
                    <el-input v-model="ruleForm.referencesTxt" style="width: 80%" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="出版社" prop="publisher" label-position="left">
                    <el-input v-model="ruleForm.publisher" style="width: 80%" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="出版日期" prop="publishDate" label-position="left">
                    <el-input v-model="ruleForm.publishDate" style="width: 80%" />
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="8">
                  <el-form-item label="章节数" prop="totalChapters" label-position="left">
                    <el-input v-model="ruleForm.totalChapters" style="width: 80%" />
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <el-form-item label="页数" prop="totalPages" label-position="left">
                    <el-input v-model="ruleForm.totalPages" style="width: 80%" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-form-item label="评价" prop="review" label-position="left">
                  <el-input v-model="ruleForm.review" />
                </el-form-item>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="转化记录" name="threes" disabled>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="转化来源" prop="name" label-position="left">
                    <el-input v-model="ruleForm.name" style="width: 80%" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="" prop="name" label-position="left">
                    <el-button>转化来源</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="date" label="序号" />
                <el-table-column prop="date" label="获取时间" />
                <el-table-column prop="name" label="转化来源" />
                <el-table-column prop="name" label="转化链接" />
                <el-table-column prop="address" label="状态" />
                <el-table-column prop="address" label="操作" />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="借阅记录" name="fourth" disabled>
              <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="date" label="借阅人" />
                <el-table-column prop="date" label="借阅日期" />
                <el-table-column prop="name" label="是否归还" />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="分享内容" name="fifth" disabled>
              待调整--建议单独抽出一个功能的页面
              <el-row>
                <el-form-item label="分享链接" prop="name" label-position="left">
                  <el-input v-model="ruleForm.name" style="width: 80%" />
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="标题" prop="name" label-position="left">
                  <el-input v-model="ruleForm.name" style="width: 80%" />
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="内容" prop="name" label-position="left">
                  <el-input v-model="ruleForm.name" style="width: 80%" />
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="分享文案" prop="name" label-position="left">
                  <el-input v-model="ruleForm.name" style="width: 80%" />
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="是否发实物图" prop="name" label-position="left">
                  <el-input v-model="ruleForm.name" style="width: 80%" />
                </el-form-item>
              </el-row>
              <el-row>
                <el-button style="margin: 0px 40%" @click="previewButton">预览</el-button>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>

import { selectByIdtApi, saveOrUpdateDataApi, checkExistApi } from "@/api/life/book/xunLikeBooks";
export default {
  data() {
    return {
      isEdit: false,
      readingStatusList: [{
        value: 1,
        label: "未开始"
      },
      {
        value: 2,
        label: "阅读中"
      },
      {
        value: 3,
        label: "停止阅读"
      },
      {
        value: 4,
        label: "结束阅读"
      }
      ],
      checked: true,
      previewShow: false,
      analyzeShow: false,
      fits: ["fill", "contain", "cover"],
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      drawer: false,
      activeName: "first",
      ruleForm: {
        category: "1"
      },
      rules: {},
      maxHeight: 400, // 设置max-height为窗口高度的一部分
      tableData: [],
      uploadUrl: "your-upload-url",
      fileList: [],
      listType: "picture-card", // 'text' or 'picture-card',
      checkBookDat: {
        errorDataInfoStatus: null,
        errorDatawarningData: null
      }
    };
  },
  mounted() {
    this.maxHeight = window.innerHeight * 0.8; // 设置max-height为窗口高度的80%
  },
  methods: {
    handleClick(tab, event) {
      if (this.previewShow) {
        this.previewShow = !this.previewShow;
      }
      if (this.analyzeShow) {
        this.analyzeShow = !this.analyzeShow;
      }

      console.log(tab, event);
    },
    show(row, type) {
      this.isEdit = false
      this.drawer = true;
      if (type == 2) {
        this.isEdit = true
        this.selectByIdtButton(row)
      }
    },
    selectByIdtButton(row) {
      selectByIdtApi(row.id).then(res => {
        this.ruleForm = res.result
      })
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.clear();
        })
        .catch((_) => { });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    clear() {
      this.checkBookDat={}
      this.ruleForm = {}
      this.drawer = false;
      this.isEdit = false
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleSuccess(response, file, fileList) {
      console.log(response, file, fileList);
    },

    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    previewButton() {
      this.previewShow = !this.previewShow;
    },
    saveOrUpdateDataButton() {
      saveOrUpdateDataApi(this.ruleForm).then(res => {
        this.clear();
        this.$parent.xunLikeBooksButtonList();
      })
    },
    checkExistButton() {
      if (this.ruleForm.name == null) {
        return this.$message.error("请将书名填完整");
      }
      var dynamicValidateForm = {
        id: 1,
        xunLikeBooksImportList: [
          {
            name: this.ruleForm.name,
            remark: null,
          },
        ],
      }
      checkExistApi(dynamicValidateForm).then((res) => {
        if (res.result.length > 0 && res.result != undefined) {
          this.checkBookDat.errorDataInfoStatus = res.result[0].infoStatus;
        this.checkBookDat.errorDatawarningData= res.result[0].warningData;
      
        }
      });
    }
  },
};
</script>
<style  lang="less" scoped>
.demo-ruleForm {
  overflow-y: auto;
  max-height: 90%; // 设置最大高度
}

.demo-drawer__footer {
  display: flex;
  justify-content: flex-end;
  padding: 0px 10px;
  border-top: 1px solid #ebeef5;
}

.ingredient-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.ingredient-row {
  width: 50%;
  margin-bottom: 20px;
  text-align: center;
  padding: 0px 20px;
  box-sizing: border-box;
}

.form-container {
  display: flex;
  align-items: center;
}

.img-container {
  margin-left: 6px;
  order: 2;
  align-self: stretch;
  display: flex;
  justify-content: flex-end;
}

.form-item-container {
  flex: 1;
  order: 1;
}

.img-container img {
  width: 80%;
  object-fit: contain;
}
</style>